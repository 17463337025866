<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col class="mb-3 mb-md-0" cols="12">
        <h1 class="font-weight-bold mb-0 text-primary">Platforms</h1>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col class="mb-3 mb-md-0" md="6">
        <b-card>
          <h4>Google</h4>
          <hr>
          <PlatformBarChart type="line" :data="single.google" :isLoading="isLoading"/>
        </b-card>
      </b-col>
      
      <b-col class="mb-3 mb-md-0" md="6">
        <b-card>
          <h4>Facebook</h4>
          <hr>
          <PlatformBarChart type="line" :data="single.facebook" :isLoading="isLoading"/>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col class="mb-3 mb-md-0" md="6">
        <b-card>
          <h4>Snapchat</h4>
          <hr>
          <PlatformBarChart type="line" :data="single.snapchat" :isLoading="isLoading"/>
        </b-card>
      </b-col>
      <b-col class="mb-3 mb-md-0" md="6">
        <b-card>
          <h4>Tiktok</h4>
          <hr>
          <PlatformBarChart type="line" :data="single.tiktok" :isLoading="isLoading"/>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col class="mb-3 mb-md-0" md="6">
        <b-card>
          <h4>Pinterest</h4>
          <hr>
          <PlatformBarChart type="line" :data="single.pinterest" :isLoading="isLoading"/>
        </b-card>
      </b-col>
      <b-col class="mb-3 mb-md-0" md="6">
        <b-card>
          <h4>Manual</h4>
          <hr>
          <PlatformBarChart type="line" :data="single.manual" :isLoading="isLoading"/>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'

import { mapActions, mapGetters } from 'vuex' 
import PlatformBarChart from '../../components/Charts/PlatformBarChart'

export default {
  mixins: [currentUser, momentMixin],
  data: () => ({
    from: null,
    to: null,
  }),
  created () {
    this.from = this.moment().subtract(1, 'month').format('YYYY-MM-DD')
    this.to = this.moment().format('YYYY-MM-DD')
    this.fetch({ from: this.from, to: this.to })
  },
  components: {
    FriendlyDate,
    PlatformBarChart
  },
  computed: {
    ...mapGetters('platforms', ['single', 'isLoading'])
  },
  methods: {
    ...mapActions('platforms', ['fetch']),
  }
}
</script>

<style>

</style>
